<template>
  <div class="gamelist--inner">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'gamelist-inner',

  inject: {
    gamelistConfig: {}
  }
}
</script>
